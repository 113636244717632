import React, { useState, useEffect } from 'react';
import './DetalhesProduto.css'; // Certifique-se de criar o arquivo de estilo DetalhesProduto.css

const DetalhesProduto = ({ produto, onRemove, onEdit }) => {
  const [edicaoAtiva, setEdicaoAtiva] = useState(false);
  const [edicaoProduto, setEdicaoProduto] = useState({ ...produto, producaoBugio: produto.producaoBugio || '' });
  const [listaLocais, setListaLocais] = useState([]);

  useEffect(() => {
    const fetchLocais = async () => {
      try {
        const response = await fetch('https://bugio.app:3000/bugio/cardapio/listarlocais');
        if (response.ok) {
          const locaisData = await response.json();
          setListaLocais(locaisData);
        } else {
          console.error('Erro ao obter locais:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao obter locais:', error);
      }
    };

    fetchLocais();
  }, []);

  const handleRemover = async () => {
    const confirmacao = window.confirm('Tem certeza de que deseja remover este produto?');

    if (confirmacao) {
      const removeSuccess = await onRemove(produto._id);

      if (removeSuccess) {
        alert('Produto removido com sucesso!');
      }
    }
  };

  const handleEditar = () => {
    setEdicaoAtiva(true);
    // Copie o valor de 'combo' para 'valorCombo' ao abrir a edição
    setEdicaoProduto({ ...produto, combo: produto.combo });
  };

  const handleSalvar = async () => {
    // Defina combo com base no valor atualizado de combo
    const editSuccess = await onEdit(edicaoProduto); // Use o estado atualizado edicaoProduto
  
    if (editSuccess) {
      setEdicaoAtiva(false);
      alert('Produto editado com sucesso!');
    }
  };

  const handleCancelar = () => {
    // Cancela a edição e volta para a exibição normal
    setEdicaoAtiva(false);
  };

  const handleChangeEdicao = (campo, valor) => {
    setEdicaoProduto((prevProduto) => ({
      ...prevProduto,
      [campo]: valor,
    }));
  };

  const handleProducaoBugioChange = (event) => {
    handleChangeEdicao('producaoBugio', event.target.value);
  };

  const handleComboChange = (event) => {
    handleChangeEdicao('combo', parseFloat(event.target.value));
  };

  const handleSubstituirImagem = async () => {
    try {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
  
      fileInput.addEventListener('change', async (event) => {
        const file = event.target.files[0];
  
        if (file) {
          // Use FormData para enviar o arquivo como parte do corpo da solicitação
          const formData = new FormData();
          formData.append('imagem', file);
  
          // Faça a solicitação para a rota /bugio/cardapio/atualizarImagem/:id
          const response = await fetch(`https://bugio.app:3000/bugio/cardapio/atualizarimagem/${edicaoProduto._id}`, {
            method: 'POST',
            body: formData,
          });
  
          if (response.ok) {
            // Atualize a imagem no estado do produto após a substituição
            const produtoAtualizado = { ...edicaoProduto, imagem: file.name };
            setEdicaoProduto(produtoAtualizado); // Atualize o estado do produto
          } else {
            console.error('Erro ao substituir imagem:', response.statusText);
          }
        }
      });
  
      // Dispare o clique no seletor de arquivo
      fileInput.click();
    } catch (error) {
      console.error('Erro ao abrir o seletor de arquivo:', error);
    }
  };

  const renderizarLocais = (locais) => {
    // Verifica se locais é um array de objetos
    if (Array.isArray(locais) && locais.length > 0 && typeof locais[0] === 'object') {
      return locais.map((local) => local.nome).join(', ');
    } else {
      // Caso contrário, assume que locais é um array de strings
      return locais.join(', ');
    }
  };

  return (
    <div className={`detalhes-produto${edicaoAtiva ? ' editando' : ''}`}>
    {!edicaoAtiva && (
      <>
        <h2>{produto.nome}</h2>
        <img src={`https://bugio.app:3000/bugio/media/${produto.imagem}`} alt={produto.nome} />
        <p>
          <strong>Código:</strong> {produto.codigo}<br />
          <strong>Descrição:</strong> {produto.descricao}<br />
          <strong>Preço:</strong> R$ {produto.valor.toFixed(2)}<br />
          {produto.combo && <><strong>Preço no Combo:</strong> {produto.combo}</>}<br />
          <strong>Locais:</strong> {renderizarLocais(produto.local)}<br />
          <strong>Unidade:</strong> {produto.unidade}<br />
          <strong>Quantidade:</strong> {produto.quantidade}<br />
          <strong>Ativo:</strong> {produto.ativo ? 'Sim' : 'Não'}<br />
          <strong>Aplica Desconto:</strong> {produto.aplicaDesconto ? 'Sim' : 'Não'}<br />
          <strong>Regra de Desconto:</strong> {produto.producaoBugio}<br />
          <strong>Prioridade:</strong> {produto.prioridade}
        </p>
      </>
    )}


      {edicaoAtiva && (
        <>
        <img
            className="clickable-image"
            src={`https://bugio.app:3000/bugio/media/${edicaoProduto.imagem}`}
            alt={edicaoProduto.nome}
            onClick={handleSubstituirImagem}
          />
          <div className="form-row">
            <label htmlFor="edicaoCodigo">Código:</label>
            <input
              type="text"
              id="edicaoCodigo"
              value={edicaoProduto.codigo}
              onChange={(e) => handleChangeEdicao('codigo', e.target.value)}
              readOnly 
            />
          </div>

          <div className="form-row">
            <label htmlFor="edicaoNome">Nome:</label>
            <input
              type="text"
              id="edicaoNome"
              value={edicaoProduto.nome}
              onChange={(e) => handleChangeEdicao('nome', e.target.value)}
            />
          </div>

          <div className="form-row">
            <label htmlFor="edicaoDescricao">Descrição:</label>
            <textarea
              id="edicaoDescricao"
              value={edicaoProduto.descricao}
              onChange={(e) => handleChangeEdicao('descricao', e.target.value)}
            />
          </div>

          <div className="form-row">
            <label htmlFor="edicaoValor">Valor:</label>
            <input
              type="number"
              id="edicaoValor"
              value={edicaoProduto.valor}
              onChange={(e) => handleChangeEdicao('valor', parseFloat(e.target.value))}
            />
          </div>

          <div className="form-row">
            <label htmlFor="edicaoCombo">Valor no combo:</label>
              <input
                type="number"
                id="edicaoCombo"
                value={edicaoProduto.combo} // Use combo em vez de valorCombo
                onChange={handleComboChange} // Use handleComboChange para atualizar combo
              />
              
          </div>

          <div className="form-row">
            <label htmlFor="edicaoLocal">Local:</label>
            <select
              id="edicaoLocal"
              multiple
              value={edicaoProduto.local}
              onChange={(e) => handleChangeEdicao('local', Array.from(e.target.selectedOptions, (option) => option.value))}
            >
              {listaLocais.map((local) => (
                <option key={local._id} value={local.nome}>
                  {local.nome}
                </option>
              ))}
            </select>
          </div>

          <div className="form-row">
            <label htmlFor="edicaoUnidade">Unidade:</label>
            <select
              id="edicaoUnidade"
              value={edicaoProduto.unidade}
              onChange={(e) => handleChangeEdicao('unidade', e.target.value)}
            >
              <option value="mililitros">Mililitros</option>
              <option value="gramas">Gramas</option>
              <option value="unidade">Unidade</option>
            </select>
          </div>

          <div className="form-row">
            <label htmlFor="edicaoQuantidade">Quantidade:</label>
            <input
              type="number"
              id="edicaoQuantidade"
              value={edicaoProduto.quantidade}
              onChange={(e) => handleChangeEdicao('quantidade', parseInt(e.target.value, 10))}
            />
          </div>

          <div className="form-row">
            <label>
            Ativo
            </label>
              <input
                type="checkbox"
                id="edicaoAtivo"
                checked={edicaoProduto.ativo}
                onChange={(e) => handleChangeEdicao('ativo', e.target.checked)}
              />
              
          </div>

          <div className="form-row">
            <label htmlFor="producaoBugio">Regra de Desconto:</label>
            <select
              id="producaoBugio"
              value={edicaoProduto.producaoBugio} // Use o valor de edicaoProduto
              onChange={handleProducaoBugioChange} // Atualize com o novo handler
            >
              <option value="">Selecione uma opção</option>
              <option value="descontoProdutosBugio">Produtos Bugio</option>
              <option value="descontoProdutosEspeciais">Produtos Especiais</option>
              <option value="descontoOutrosProdutos">Outros Produtos</option>
              <option value="descontoTabaco">Tabaco</option>
              <option value="descontoKombucha">Kombucha</option>
              <option value="descontoJambu">Jambu</option>
            </select>
          </div>


          <div className="form-row">
            <label>
            Aplica desconto?
            </label>
              <input
                type="checkbox"
                id="edicaoAplicaDesconto"
                checked={edicaoProduto.aplicaDesconto}
                onChange={(e) => handleChangeEdicao('aplicaDesconto', e.target.checked)}
              />
              
          </div>

          <div className="form-row">
            <label htmlFor="edicaoPrioridade">Prioridade:</label>
            <input
              type="range"
              id="edicaoPrioridade"
              min="0"
              max="100"
              value={edicaoProduto.prioridade}
              onChange={(e) => handleChangeEdicao('prioridade', parseInt(e.target.value, 10))}
            />
            <p>{edicaoProduto.prioridade} </p>
          </div>
        

          

          <div className="icon-group">
            <button className="save-icon" onClick={handleSalvar}>
              Salvar
            </button>
            <button className="cancel-icon" onClick={handleCancelar}>
              Cancelar
            </button>
          </div>
        </>
      )}
      {!edicaoAtiva && (
        <div className="icon-group">
          <button className="edit-icon" onClick={handleEditar}>
            Editar
          </button>
          <button className="remove-icon" onClick={handleRemover}>
            Remover
          </button>
        </div>
      )}
    </div>
  );
};

export default DetalhesProduto;
