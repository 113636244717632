// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardapio-page {
    background: linear-gradient(to bottom, #3498db, #8e44ad);
    display: flex;
    align-items: center;
    flex-direction: column; /* Mude a direção para uma coluna */
    min-height: 100vh;
    margin: 0;
  }
  
  .cardapio-content {
    display: flex;
    justify-content: center; /* Centraliza os elementos horizontalmente */
  }
  
  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .row {
    display: flex;
    flex-direction: row;
  }
  
  @media (max-width: 768px) {
    .cardapio-content {
        flex-direction: column; /* Conteúdo disposto em coluna */
        align-items: center;
    }

    .row {
        flex-direction: column; /* Componentes um em cima do outro */
        align-items: center; /* Alinhar ao centro */
        width: 100%; /* Ocupa a largura total */
    }
}`, "",{"version":3,"sources":["webpack://./src/Cardapio.css"],"names":[],"mappings":"AAAA;IACI,wDAAwD;IACxD,aAAa;IACb,mBAAmB;IACnB,sBAAsB,EAAE,mCAAmC;IAC3D,iBAAiB;IACjB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,uBAAuB,EAAE,4CAA4C;EACvE;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE;QACI,sBAAsB,EAAE,gCAAgC;QACxD,mBAAmB;IACvB;;IAEA;QACI,sBAAsB,EAAE,oCAAoC;QAC5D,mBAAmB,EAAE,sBAAsB;QAC3C,WAAW,EAAE,0BAA0B;IAC3C;AACJ","sourcesContent":[".cardapio-page {\n    background: linear-gradient(to bottom, #3498db, #8e44ad);\n    display: flex;\n    align-items: center;\n    flex-direction: column; /* Mude a direção para uma coluna */\n    min-height: 100vh;\n    margin: 0;\n  }\n  \n  .cardapio-content {\n    display: flex;\n    justify-content: center; /* Centraliza os elementos horizontalmente */\n  }\n  \n  .column {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .row {\n    display: flex;\n    flex-direction: row;\n  }\n  \n  @media (max-width: 768px) {\n    .cardapio-content {\n        flex-direction: column; /* Conteúdo disposto em coluna */\n        align-items: center;\n    }\n\n    .row {\n        flex-direction: column; /* Componentes um em cima do outro */\n        align-items: center; /* Alinhar ao centro */\n        width: 100%; /* Ocupa a largura total */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
