import React, { useState, useEffect } from 'react';
import './DetalhesUsuario.css';
import HistoricoMovimentacao from './HistoricoMovimentacao'; // Importe o novo componente
import { FaLocationArrow, FaCalendarAlt, FaInstagram, FaUserAlt, FaTrashAlt, FaTimesCircle, FaDollarSign, FaEdit, FaRegEnvelope, FaIdCard, FaHashtag, FaMusic, FaCameraRetro, FaExchangeAlt, FaSmile } from 'react-icons/fa';
import { GiMonkey } from 'react-icons/gi';

function DetalhesUsuario({
  usuario,
  onClose,
  setUsuarios,
  setUsuarioSelecionado,
  usuarios,
  setNome,
  setEmail,
  setCPF,
  setTipo,
}) {
  const [modoEdicao, setModoEdicao] = useState(false);
  const [historicoAberto, setHistoricoAberto] = useState(false); // Estado para controlar a visibilidade do histórico
  const [tipoUsuarioLogado, setTipoUsuarioLogado] = useState(null);
  const [classesUsuarios, setClassesUsuarios] = useState([]); // Estado para armazenar as classes de usuários

  useEffect(() => {
    fetch('https://bugio.app:3000/bugio/classesusuarios/listarclasses')
      .then((response) => response.json())
      .then((data) => {
        setClassesUsuarios(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar a lista de classes de usuários: ', error);
      });
  }, []);

  const toggleModoEdicao = () => {
    setModoEdicao(!modoEdicao);
  };

  const handleHistorico = () => {
    setHistoricoAberto(!historicoAberto); // Alternar a visibilidade do histórico
  };

  useEffect(() => {
    // Redefinir o modo de edição quando um novo usuário for selecionado
    setModoEdicao(false);
  
    // Restaurar os dados iniciais do usuário
    // Certifique-se de atualizar isso com base nos dados reais que você tem disponíveis
    // Este é apenas um exemplo, ajuste conforme necessário
    if (usuario) {
      const { nome, email, cpf, tipo } = usuario;
      // Atualizar o estado interno com os novos dados do usuário
      setNome(nome || '');
      setEmail(email || '');
      setCPF(cpf || '');
      setTipo(tipo || '');
    }
  }, [usuario]);

  // Função para obter o tipo de usuário logado
  const obterTipoUsuarioLogado = () => {
    const userId = localStorage.getItem('userId'); // Obter userId do local storage
    if (userId) {
      // Fazer requisição para obter informações do usuário logado
      fetch(`https://bugio.app:3000/bugio/usuario/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          setTipoUsuarioLogado(data.tipo);
        })
        .catch((error) => {
          console.error('Erro ao obter informações do usuário logado: ', error);
        });
    }
  };

  useEffect(() => {
    obterTipoUsuarioLogado();
  }, []);

  const handleSalvar = () => {

    if (tipoUsuarioLogado !== 'Administrador') {
      alert('Você não tem permissão para salvar alterações. Apenas usuários do tipo "Administrador" podem fazer isso.');
      return;
    }

    const novoNome = document.getElementById('nome-input').value;
    const novoEmail = document.getElementById('email-input').value;
    const novoCPF = document.getElementById('cpf-input').value;
    const novoTipo = document.getElementById('tipo-input').value;


    // Lógica para fazer a requisição para salvar as informações editadas no servidor
    fetch(`https://bugio.app:3000/bugio/atualizarusuario/${usuario._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        nome: novoNome,
        email: novoEmail,
        cpf: novoCPF,
        tipo: novoTipo,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        toggleModoEdicao(); // Volta para o modo de visualização após a edição

        // Atualize o usuário no estado com as informações editadas
        const usuarioAtualizado = { ...usuario, nome: novoNome, email: novoEmail, cpf: novoCPF, tipo: novoTipo };
        setUsuarioSelecionado(usuarioAtualizado);

        // Atualize a lista de usuários no estado com as informações editadas
        const usuariosAtualizados = usuarios.map((u) => (u._id === usuario._id ? usuarioAtualizado : u));
        setUsuarios(usuariosAtualizados);
      })
      .catch((error) => {
        console.error('Erro ao atualizar usuário: ', error);
      });
  };

  const handleRemover = () => {
    const confirmacao = window.confirm("Tem certeza de que deseja remover o cadastro?");

    if (confirmacao) {
      // Lógica para fazer a requisição para excluir o cadastro no servidor
      fetch(`https://bugio.app:3000/bugio/removerusuario/${usuario._id}`, {
        method: 'DELETE',
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          onClose(); // Fecha o detalhes do usuário após a remoção

          // Remova o usuário da lista
          const usuariosAtualizados = usuarios.filter((u) => u._id !== usuario._id);
          setUsuarios(usuariosAtualizados);
        })
        .catch((error) => {
          console.error('Erro ao remover usuário: ', error);
        });

      // Remova o usuário da lista
      const usuariosAtualizados = usuarios.filter((u) => u._id !== usuario._id);
      setUsuarios(usuariosAtualizados);
    }
  };

  const handleResetarSenha = () => {
    const confirmacao = window.confirm("Tem certeza de que deseja resetar a senha deste usuário?");

    if (confirmacao) {
      // Lógica para fazer a requisição para resetar a senha no servidor
      fetch(`https://bugio.app:3000/bugio/resetarsenha/${usuario._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          alert('Senha resetada com sucesso.'); // Informe o usuário de que a senha foi resetada
        })
        .catch((error) => {
          console.error('Erro ao resetar a senha: ', error);
          alert('Erro ao resetar a senha. Tente novamente mais tarde.');
        });
    }
  };

  const formatarData = (data) => {
    if (!data) return '';
    const dataObj = new Date(data);
    const dia = String(dataObj.getDate()).padStart(2, '0');
    const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
    const ano = dataObj.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  return (
    <div className="detalhes-usuario">
      <div className="close-icon" onClick={onClose}>
        <FaTimesCircle className="custom-icon-color" />
      </div>
  
      {(usuario.caminhoImagemPerfil) ? (
        <img
          src={`https://bugio.app:3000/bugio/media/${usuario.caminhoImagemPerfil}`}
          alt="Imagem do Perfil"
          className="user-profile-image"
        />
      ) : (
        <div className="user-profile-icon">
          <FaUserAlt className="user-icon" />
          {!modoEdicao && <p className="warn-profile">Solicite ao usuário que atualize sua foto de perfil no app.</p>}
        </div>
      )}
  
      {modoEdicao ? (
        <>
          <label htmlFor="nome-input">Nome:</label>
          <input id="nome-input" type="text" defaultValue={usuario.nome} />
          <label htmlFor="email-input">Email:</label>
          <input id="email-input" type="text" defaultValue={usuario.email} />
          <label htmlFor="cpf-input">CPF:</label>
          <input id="cpf-input" type="text" defaultValue={usuario.cpf} />
          <label htmlFor="tipo-input">Classe:</label>
          <select id="tipo-input" defaultValue={usuario.tipo}>
            <option value="">Selecione...</option>
            {classesUsuarios.map((classe) => (
              <option key={classe._id} value={classe.nome}>
                {classe.nome}
              </option>
            ))}
          </select>
          <button className="botao-verde" onClick={handleSalvar}>Salvar</button>
          <button className="botao-vermelho" onClick={handleResetarSenha}>Resetar Senha</button>
        </>
      ) : (
        <>
          <h2>{usuario.nome}</h2>
          {usuario.tipo && <p>
            {usuario.tipo === 'Administrador' && <FaHashtag />}
            {usuario.tipo === 'Artista' && <FaMusic />}
            {usuario.tipo === 'Fotógrafo' && <FaCameraRetro />}
            {usuario.tipo === 'Parceiro' && <FaExchangeAlt />}
            {usuario.tipo === 'Cliente' && <FaSmile />}
            {usuario.tipo === 'Funcionário' && <GiMonkey />}
            {usuario.tipo === 'Responsável' && <GiMonkey />}
            {' '}{usuario.tipo}
          </p>}
          {usuario.email && <p>
            <FaRegEnvelope /> {usuario.email}
          </p>}
          {usuario.cpf && <p>
            <FaIdCard /> {usuario.cpf}
          </p>}
        </>
      )}
  
      {historicoAberto && <HistoricoMovimentacao userId={usuario._id} tipoUsuarioLogado={tipoUsuarioLogado} />}
      <div className="icon-group">
      {(tipoUsuarioLogado === 'Administrador' || usuario.caminhoImagemPerfil) && <FaDollarSign className="saldo-icon custom-icon-color" onClick={handleHistorico} />}
        <FaEdit className="edit-icon custom-icon-color" onClick={toggleModoEdicao} />
      </div>
    </div>
  );
}

export default DetalhesUsuario;
