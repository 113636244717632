import React, { useState, useEffect } from 'react';
import './GerenciarClassesUsuarios.css'; // Importando o estilo fornecido
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const GerenciarClassesUsuarios = () => {
  const [acao, setAcao] = useState(null);
  const [nomeClasse, setNomeClasse] = useState('');
  const [descontoProdutosBugio, setDescontoProdutosBugio] = useState(0);
  const [descontoOutrosProdutos, setDescontoOutrosProdutos] = useState(0);
  
  // Novos estados para os descontos
  const [descontoTabaco, setDescontoTabaco] = useState(0);
  const [descontoKombucha, setDescontoKombucha] = useState(0);
  const [descontoJambu, setDescontoJambu] = useState(0);
  const [descontoProdutosEspeciais, setDescontoProdutosEspeciais] = useState(0); // Novo estado

  const [classesUsuarios, setClassesUsuarios] = useState([]);
  const [classeEditando, setClasseEditando] = useState(null);

  const apiUrl = 'https://bugio.app:3000/bugio/classesusuarios';

  const handleAdicionar = () => {
    setAcao('Adicionar');
    setNomeClasse('');
    setDescontoProdutosBugio(0);
    setDescontoOutrosProdutos(0);
    setDescontoTabaco(0); // Resetando novo estado
    setDescontoKombucha(0); // Resetando novo estado
    setDescontoJambu(0); // Resetando novo estado
    setDescontoProdutosEspeciais(0); // Resetando novo estado
    setClasseEditando(null);
  };

  const handleEditar = async () => {
    setAcao('Editar');
    setClasseEditando(null);
    try {
      const response = await fetch(`${apiUrl}/listarclasses`);
      const data = await response.json();
      setClassesUsuarios(data);
    } catch (error) {
      console.error('Erro ao obter classes de usuários:', error);
    }
  };

  const handleEditarClasse = (classe) => {
    setClasseEditando(classe);
    setNomeClasse(classe.nome);
    setDescontoProdutosBugio(classe.descontoProdutosBugio);
    setDescontoOutrosProdutos(classe.descontoOutrosProdutos);
    setDescontoTabaco(classe.descontoTabaco); // Setando novo estado
    setDescontoKombucha(classe.descontoKombucha); // Setando novo estado
    setDescontoJambu(classe.descontoJambu); // Setando novo estado
    setDescontoProdutosEspeciais(classe.descontoProdutosEspeciais); // Setando novo estado
  };

  const handleExcluirClasse = async (id) => {
    const confirmarExclusao = window.confirm('Tem certeza que deseja excluir esta classe de usuário?');
    
    if (confirmarExclusao) {
      try {
        const response = await fetch(`${apiUrl}/excluirclasse/${id}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          console.log('Classe de usuário excluída com sucesso.');
          const responseClasses = await fetch(`${apiUrl}/listarclasses`);
          const dataClasses = await responseClasses.json();
          setClassesUsuarios(dataClasses);
        } else {
          console.error('Erro ao excluir classe de usuário:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao excluir classe de usuário:', error);
      }
    }
  };

  const handleSalvarEdicao = async () => {
    try {
      const response = await fetch(`${apiUrl}/editarclasse/${classeEditando._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome: nomeClasse,
          descontoProdutosBugio: descontoProdutosBugio,
          descontoOutrosProdutos: descontoOutrosProdutos,
          descontoTabaco, // Adicionando novo campo
          descontoKombucha, // Adicionando novo campo
          descontoJambu, // Adicionando novo campo
          descontoProdutosEspeciais, // Adicionando novo campo
        }),
      });
  
      if (response.ok) {
        console.log('Classe de usuário editada com sucesso.');
        const responseClasses = await fetch(`${apiUrl}/listarclasses`);
        const dataClasses = await responseClasses.json();
        setClassesUsuarios(dataClasses);
        setNomeClasse('');
        setDescontoProdutosBugio(0);
        setDescontoOutrosProdutos(0);
        setDescontoTabaco(0); // Resetando após edição
        setDescontoKombucha(0); // Resetando após edição
        setDescontoJambu(0); // Resetando após edição
        setDescontoProdutosEspeciais(0); // Resetando após edição
        setClasseEditando(null);
      } else {
        console.error('Erro ao editar classe de usuário:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao editar classe de usuário:', error);
    }
  };

  const handleCadastrar = async () => {
    try {
      const response = await fetch(`${apiUrl}/adicionarclasse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome: nomeClasse,
          descontoProdutosBugio,
          descontoOutrosProdutos,
          descontoTabaco, // Adicionando novo campo
          descontoKombucha, // Adicionando novo campo
          descontoJambu, // Adicionando novo campo
          descontoProdutosEspeciais, // Adicionando novo campo
        }),
      });

      if (response.ok) {
        console.log('Classe de usuário cadastrada com sucesso.');
        const responseClasses = await fetch(`${apiUrl}/listarclasses`);
        const dataClasses = await responseClasses.json();
        setClassesUsuarios(dataClasses);
        setNomeClasse('');
        setDescontoProdutosBugio(0);
        setDescontoOutrosProdutos(0);
        setDescontoTabaco(0); // Resetando após cadastro
        setDescontoKombucha(0); // Resetando após cadastro
        setDescontoJambu(0); // Resetando após cadastro
        setDescontoProdutosEspeciais(0); // Resetando após cadastro
        setClasseEditando(null);
      } else {
        console.error('Erro ao cadastrar classe de usuário:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao cadastrar classe de usuário:', error);
    }
  };

  const handleCancelar = () => {
    setAcao(null);
    setNomeClasse('');
    setClasseEditando(null);
  };

  return (
    <div className="classes-usuarios-list-card">
      <h2>Gerenciar Classes de Usuários</h2>
      <div className="acao-buttons">
        <button className={acao === 'Adicionar' ? 'acao-button selecionado' : 'acao-button'} onClick={handleAdicionar}>
          Nova Classe
        </button>
        <button className={acao === 'Editar' ? 'acao-button selecionado' : 'acao-button'} onClick={handleEditar}>
          Editar Classes
        </button>
      </div>
  
      {acao === 'Adicionar' && (
        <div>
          <label>Nome:</label>
          <input type="text" value={nomeClasse} onChange={(e) => setNomeClasse(e.target.value)} />
  
          <label>Desconto em Produtos Bugio (%):</label>
          <input type="number" value={descontoProdutosBugio} onChange={(e) => setDescontoProdutosBugio(e.target.value)} />
  
          <label>Desconto em Outros Produtos (%):</label>
          <input type="number" value={descontoOutrosProdutos} onChange={(e) => setDescontoOutrosProdutos(e.target.value)} />

          {/* Novos campos para descontos */}
          <label>Desconto em Tabaco (R$):</label>
          <input type="number" value={descontoTabaco} onChange={(e) => setDescontoTabaco(e.target.value)} />

          <label>Desconto em Kombucha (%):</label>
          <input type="number" value={descontoKombucha} onChange={(e) => setDescontoKombucha(e.target.value)} />

          <label>Desconto em Jambu (%):</label>
          <input type="number" value={descontoJambu} onChange={(e) => setDescontoJambu(e.target.value)} />

          <label>Desconto em Produtos Especiais (%):</label> {/* Novo campo */}
          <input type="number" value={descontoProdutosEspeciais} onChange={(e) => setDescontoProdutosEspeciais(e.target.value)} /> {/* Novo campo */}

          <button onClick={handleCadastrar}>Cadastrar</button>
          <button className="cancelar-button" onClick={handleCancelar}>Cancelar</button>
        </div>
      )}
  
      {acao === 'Editar' && (
        <div>
          {classesUsuarios.map((classe) => (
            <div key={classe._id} className="classe-item">
              <span>{classe.nome}</span>
              <div className="classe-icons">
                <FaEdit onClick={() => handleEditarClasse(classe)} className="edit-icon" />
                <FaTrashAlt onClick={() => handleExcluirClasse(classe._id)} className="delete-icon" />
              </div>
            </div>
          ))}
  
          {classeEditando && (
            <div>
              <h3>Editando: {classeEditando.nome}</h3>
              <label>Nome:</label>
              <input type="text" value={nomeClasse} onChange={(e) => setNomeClasse(e.target.value)} />
  
              <label>Desconto em Produtos Bugio (%):</label>
              <input type="number" value={descontoProdutosBugio} onChange={(e) => setDescontoProdutosBugio(e.target.value)} />
  
              <label>Desconto em Outros Produtos (%):</label>
              <input type="number" value={descontoOutrosProdutos} onChange={(e) => setDescontoOutrosProdutos(e.target.value)} />

              {/* Novos campos para descontos */}
              <label>Desconto em Tabaco (R$):</label>
              <input type="number" value={descontoTabaco} onChange={(e) => setDescontoTabaco(e.target.value)} />

              <label>Desconto em Kombucha (%):</label>
              <input type="number" value={descontoKombucha} onChange={(e) => setDescontoKombucha(e.target.value)} />

              <label>Desconto em Jambu (%):</label>
              <input type="number" value={descontoJambu} onChange={(e) => setDescontoJambu(e.target.value)} />

              <label>Desconto em Produtos Especiais (%):</label> {/* Novo campo */}
              <input type="number" value={descontoProdutosEspeciais} onChange={(e) => setDescontoProdutosEspeciais(e.target.value)} /> {/* Novo campo */}

              <button onClick={handleSalvarEdicao}>Salvar Edição</button>
              <button className="cancelar-button" onClick={handleCancelar}>Cancelar</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GerenciarClassesUsuarios;
